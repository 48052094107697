import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {logo} from './images/index';
import "./styles/MainNavbarStyle.css";
import Footer from "./Component/LayoutComp/Footer";
import GoToTop from "./Component/LayoutComp/GoToTop";
import { Cursor } from "react-creative-cursor";
import Newsletter from "./Component/Common/NewsLetter";
import WhatsApp from "./Component/LayoutComp/WhatsApp";
import "react-creative-cursor/dist/styles.css";

const MainNavbar = () => {
  const phoneNumber = "+91-7972156073";

  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  const [menu, setMenu] = React.useState(true);

  // Function to toggle the navbar menu
  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  }, []);

  // Function to close the navbar when a link is clicked
  const closeNavbar = () => {
    if (!menu) {
      toggleNavbar();
    }
  };

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const textColorStyle = isScrolled ? { color: "white" } : { color: "black" };

  const logoStyle = {
    height: "60px",
    width: "auto",
  };

  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const pathsToShowFooter = ["/", "/about", "/service", "/portfolio", "/contact"];
  const shouldShowFooter = pathsToShowFooter.includes(location.pathname);

  return (
    <>
      <div id="navbar" className="navbar-area">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container p-0">
            <Link to="/" className="navbar-brand  p-0 ms-2">
              <img
                src={logo}
                style={logoStyle}
                alt="logo"
              />
            </Link>



            {/* Toggle navigation */}
            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>

            <div
              className={classOne}
              id="navbarSupportedContent"
              style={textColorStyle}
            >
              <ul className="navbar-nav" onClick={closeNavbar}>
                <li className="nav-item" data-cursor-text=" "
            data-cursor-size="5px">
                  <Link
                    to="/"
                    className={`nav-link ${
                      currentPath === "/" ? "active" : ""
                    }`}
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item" data-cursor-text=" "
            data-cursor-size="5px" >
                  <Link
                    to="/about"
                    className={`nav-link ${
                      currentPath === "/about" ? "active" : ""
                    }`}
                  >
                    About us
                  </Link>
                </li>

                <li className="nav-item" data-cursor-text=" "
            data-cursor-size="5px">
                  <Link
                    to="/service"
                    className={`nav-link ${
                      currentPath === "/service" ? "active" : ""
                    }`}
                  >
                    Services
                  </Link>
                </li>

                <li className="nav-item" data-cursor-text=" "
            data-cursor-size="5px">
                  <Link
                    to="/portfolio"
                    className={`nav-link ${
                      currentPath === "/portfolio" ? "active" : ""
                    }`}
                  >
                    Portfolio
                  </Link>
                </li>

                <li className="nav-item" data-cursor-text=" "
            data-cursor-size="5px">
                  <Link
                    to="/contact"
                    className={`nav-link ${
                      currentPath === "/contact" ? "active" : ""
                    }`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>

              <div className="others-options">
                <button className="btn-cssbuttons">
                  <span>Lets Connect</span>
                  <span>
                    <i className="fa-regular fa-user"></i>
                  </span>
                  <ul className="nav-item">
                    <li>
                      <a
                        href={`tel:${phoneNumber}`}
                        className="nav-link text-white text-center d-flex align-items-center justify-content-center"
                      >
                        <i className="fa-solid fa-phone text-white fs-6 mx-2"></i>{" "}
                        7972156073
                      </a>
                    </li>
                  </ul>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Outlet />
   
      <GoToTop />
      <div className="d-none d-lg-block">
        <Cursor isGelly={true} />
      </div>
      <WhatsApp />
      <Newsletter/>
      <Footer />
    </>
  );
};

export default MainNavbar;
