import '../../styles/Loader.css';
const LoaderComp = () => {
    return (
        <>
         <div className='wrapper'>
         <div className="three-body d-flex justify-content-center align-items-center">
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
                <div className="three-body__dot"></div>
            </div>
         </div>
        </>
    )
}
export default LoaderComp;