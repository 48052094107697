import React, { useState, useEffect } from 'react';
import '../../styles/GoToTopButton.css'; // You can create a CSS file for styling

const GoToTop = () => {
  const [scrollY, setScrollY] = useState(0);
  const calculateScrollProgress = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY;

    const progress = (scrollPosition / (documentHeight - windowHeight)) * 100;
    setScrollY(progress);
  };


  useEffect(() => {
    window.addEventListener('scroll', calculateScrollProgress);
    return () => {
      window.removeEventListener('scroll', calculateScrollProgress);
    };
  }, []);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="go-to-top-container">
      <div
        className="go-to-top-button"
        onClick={scrollToTop}
        style={{ opacity: scrollY > 20 ? 1 : 0 }} 
      >
        <span><i className="fa-solid fa-jet-fighter-up fs-3"></i></span>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${scrollY}%` }} />
      </div>
    </div>
  );
};

export default GoToTop;
