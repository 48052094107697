import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoaderComp from "./Component/Common/LoaderComp.js";

import NotFound from "./pages/NotFound.js";
// const Navbar = lazy(() => import("./Navbar.js"));
import MainNavbar from "./MainNavbar.js";
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Contact = lazy(() => import("./pages/Contact"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const LED = lazy(() => import("./pages/signagesDetails/LED"));
const NonLite = lazy(() => import("./pages/signagesDetails/Non-lite"));
const Van = lazy(() => import("./pages/signagesDetails/Van"));
const Glow = lazy(() => import("./pages/signagesDetails/Glow"));
const Shop = lazy(() => import("./pages/signagesDetails/Shop"));
const Event = lazy(() => import("./pages/signagesDetails/Event"));
const ACP = lazy(() => import("./pages/signagesDetails/ACP"));



const Layout = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div id="global-loader">
            <LoaderComp />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<MainNavbar />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="service" element={<Service />} />
            <Route path="contact" element={<Contact />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="service/led" element={<LED />} />
            <Route path="service/shop" element={<Shop />} />
            <Route path="service/non_lite" element={<NonLite />} />
            <Route path="service/van" element={<Van />} />
            <Route path="service/event" element={<Event />} />
            <Route path="service/glow" element={<Glow />} />
            <Route path="service/acp" element={<ACP />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Layout;
