// src/components/WhatsAppButton.js
import React from 'react';
const whatStyle = {
    position: "fixed",
    right: "25px",
    top: "73vh",
    zIndex:"100"
}
const WhatsIcon={
    fontSize:"3rem",
    color:"green",
    textStroke:"white 10px",
    borderRadius:"50%",
    backdropFilter: "blur(5px)",
}
const WhatsApp = () => {
    const phoneNumber = '7972156073';
    const message = 'Hello, I want to connect with you!';

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    return (
        <a href={whatsappLink} target="_blank" style={whatStyle} rel="noopener noreferrer">
            <i className="fa-brands fa-whatsapp" style={WhatsIcon}></i>
        </a>
    );
};

export default WhatsApp;
